import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import styled from 'styled-components'
import AspectRatio from 'react-aspect-ratio';

import Layout from "../components/layout"
import SEO from "../components/seo"
import VideoModal from '../components/video-modal'
import Accordion from '../components/accordion';
import RelatedGuides from '../components/related-guides';
import PhoneShot from '../components/phone-shot';
import CTA from '../components/cta';
import get from 'lodash.get';

const Container = styled.div`
  max-width: 793px;
  width:100%;
  margin: auto;
`

const Title = styled.h1`
  font-size:46px;
`

const Text = styled.div`
  max-width: 588px;
  width:100%;
  margin: auto;
`

const Callout = styled.div`
  max-width: 792px;
  margin: auto;
`

const InfoIcon = styled.div`
  top:.9rem;
  right:.9rem;
  height:23px;
  width:23px;

  img {
    height:100%;
    width:100%;
  }
`

const Os = styled.div`
  img {
    @media screen and (max-width:640px) {
      max-width:33px;
    }
  }
`

const ResponsiveEmbed = styled.div`
  position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; background:#eee;
  iframe {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
  }
`


const QuoteIcon = styled.div`
max-width:45px;
@media(min-width:768px) {
  max-width:70px;
}
`

const numPrefix = (number) => {
  return number < 10 ? '0'+number : number;
}

export default class GuidesItem extends React.Component {
  constructor(props) {
    super(props);
    this.page = props.data.page;
  }

  render() {
    const { acf, yoast_meta } = this.page;

    let heroTitle = acf.hero && acf.hero.heading && acf.hero.heading.length ? acf.hero.heading : this.page.title;
    const operatingSystems = acf.guide?.operating_systems || [];

    return (
      <Layout>
        <SEO yoast={yoast_meta} />

        {/* Hero */}
        <section className="md:mb-16 mt-8 sm:mt-12 md:mt-20">
          <div className="o-wrapper">
            <Container>
              <Title className="u-h2 mb-6" dangerouslySetInnerHTML={{ __html: heroTitle }} />
              { acf.hero && acf.hero.content && acf.hero.content.length && <p className="max-w-2xl -mb-2 lg:mb-0">{ acf.hero.content }</p> }
            </Container>
          </div>
        </section>

        {/* Video */}
        {acf.hero && acf.hero.video_type && acf.hero.video_type === 'modal' && acf.hero.video.length &&
          <VideoModal
            poster={acf.hero.image}
            video={acf.hero.video_url}
            embedType={acf.hero.video_embed_type}
            aspectRatio="16/9"
          />
        }

        {acf.hero && acf.hero.video_type && acf.hero.video_type === 'inline' && acf.hero.video_url && acf.hero.video_url.length &&
          <section className="md:my-16 my-12">
            <div className="o-wrapper">
              <div className="mx-auto" style={{maxWidth:'996px'}} >
                {acf.hero.video_embed_type === 'iframe' &&
                  <ResponsiveEmbed className="embed">
                    <iframe src={acf.hero.video_url} title="Video" frameBorder="0">
                    </iframe>
                  </ResponsiveEmbed>
                }

                {acf.hero.video_embed_type === 'html5' &&
                  // eslint-disable-next-line
                  <video className="w-full" controls src={acf.hero.video_url}></video>
                }
              </div>
            </div>
          </section>
        }

        {acf.content_guides && acf.content_guides.map((section, i) => {
          switch (section.__typename) {
            case "WordPressAcf_introduction":
              return (
                // Introduction
                <section key={section.id} className="md:mt-16 md:mb-20 my-12">
                  <div className="o-wrapper">
                    <Container className="sm:py-12 py-10 border-t border-b border-grey-mid">
                      <h3 className="u-h5 sm:mb-10 mb-8">{section.heading}</h3>
                      <div className={`grid md:grid-cols-${operatingSystems.length > 0 ? '4': '3'} grid-cols-2 gap-8 row-gap-5`}>
                        {/* Operating Systems */}
                        {operatingSystems.length > 0 &&
                          <div>
                            <h5 className="font-medium">Operating system{operatingSystems.length === 1? '' :'s'}</h5>
                            <div className="flex flex-wrap -ml-2">
                              {operatingSystems.map((os, i)=>
                                <Os className="pl-2 md:mt-4 mt-2" key={`intro-${os+i}`}>
                                  <img src={require(`../images/icons/${os}.svg`)} alt={os} />
                                </Os>
                              )}
                            </div>
                          </div>
                        }

                        {section.columns && section.columns.length > 0 && section.columns.map((col, cnt) =>
                          <div className="flex flex-col" key={section.id+cnt}>
                            <h5 className="font-medium">{col.heading}</h5>
                            <div className="flex-auto flex flex-col justify-start md:mt-6 sm:mt-2">
                              <div dangerouslySetInnerHTML={{__html:col.content}} />
                            </div>
                          </div>
                        )}
                      </div>
                    </Container>
                  </div>
                </section>
              )

            case "WordPressAcf_text":
              // Text
              return (
                <section key={section.id} className="md:my-20 my-16">
                  <div className="o-wrapper">
                    <Text
                      className="post-content"
                      dangerouslySetInnerHTML={{ __html: section.text }}
                    />
                  </div>
                </section>
              )
            case "WordPressAcf_callout":
              // Callout
              return (
                <section key={section.id} className="md:my-20 my-16">
                  <div className="o-wrapper">
                    <Callout className="border-2 border-grey-mid md:p-12 p-16 px-4 text-center relative">
                      <InfoIcon className="absolute">
                        <img src={require(`../images/icons/info.svg`)} alt="Info" />
                      </InfoIcon>

                      <h3 className="u-h5 mb-3">{section.heading}</h3>
                      <div
                        className="max-w-lg mx-auto"
                        dangerouslySetInnerHTML={{ __html:section.text }}
                      />
                    </Callout>
                  </div>
                </section>
              )

            case "WordPressAcf_video_modal":
              // Text
              return (
                <div key={section.id}>
                  <Container>
                    <VideoModal
                      video={section.video_url}
                      embedType={section.video_embed_type}
                      poster={section.poster_image}
                      aspectRatio="793/442"
                    />
                  </Container>
                </div>
              )
            case "WordPressAcf_embedded_video":
              return (
                <section key={section.id} className="md:my-24 sm:my-20 my-16">
                  <div className="o-wrapper">
                    <Container>
                      {section.video_embed_type && section.video_embed_type === 'iframe' &&
                        <ResponsiveEmbed className="embed">
                          <iframe src={section.video_url} title="Video" frameBorder="0">
                          </iframe>
                        </ResponsiveEmbed>
                      }

                      {section.video_embed_type && section.video_embed_type === 'html5' &&
                        // eslint-disable-next-line
                        <video className="w-full" controls src={section.video_url}></video>
                      }
                    </Container>
                  </div>
                </section>
              )
            case "WordPressAcf_accordion":
              // Text
              return (
                <section key={section.id} className="my-12">
                  <div className="o-wrapper">
                    <Text>
                      <Accordion
                        items={section.accordion}
                      />
                    </Text>
                  </div>
                </section>
              )

            case "WordPressAcf_cta":
              // CTA
              return (
                <section key={section.id} className="md:my-20 my-16">
                  <div className="o-wrapper">
                    <Container style={{backgroundColor:'#f2f3f5'}}>
                      {/* eslint-disable-next-line */}
                      <div onClick={() => window && window.LC_API && window.LC_API.show_full_view()} className="py-8 md:py-6 -ml-6 px-6 text-center md:text-left md:flex flex-wrap justify-between items-center cursor-pointer">
                        <h3 className="u-h5 flex-grow pl-6">{section.heading}</h3>
                        <div className="md:mt-0 mt-3 pl-6">
                          <button className="w-full flex justify-center items-center">
                            {section.button?.title}
                            <svg width="24" height="13" className="relative ml-3" style={{top:'2px' }} xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" stroke="#2E2E2E" strokeWidth="1.5" fill="none" fillRule="evenodd"><path d="M16.8 0L22.4 5.6 16.8 11.2"/><path d="M22.4 5.6L0.5 5.5"/></g></svg>
                          </button>
                        </div>
                      </div>
                    </Container>
                  </div>
                </section>
              )

            case "WordPressAcf_two_column_image_text":
              // Two Column Image & Text
              return (
                <section key={section.id} className="lg:my-24 md:my-20 my-16">
                  <div className="o-wrapper">
                    <div className="grid md:grid-cols-2 gap-8 items-center">
                      {section.image && section.image.localFile && section.image.localFile.childImageSharp &&
                        <AspectRatio ratio="1/1" className="bg-grey-lighter">
                          <div>
                            <Image fluid={section.image.localFile.childImageSharp.fluid} style={{ maxHeight:'100%' }} draggable={false} />
                          </div>
                        </AspectRatio>
                      }
                      <div className="md:max-w-md mx-auto lg:px-4">
                        <h3 className="u-h3 font-normal mb-6">{section.heading }</h3>
                        <div dangerouslySetInnerHTML={{ __html: section.content }} />
                      </div>
                    </div>
                  </div>
                </section>
              )

            case "WordPressAcf_large_ordered_list":
              // Large Ordered List
              return (
                <section key={section.id} className="md:my-20 my-16">
                  <div className="o-wrapper">
                    <Text>
                      {section.list &&section.list.length> 0 && section.list.map((itm, cnt)=>
                      <div className="md:flex -ml-10 sm:mb-12 mb-8 md:mb-16" key={section.id+cnt}>
                        <div className="pl-10">
                          <div className="u-h2 mb-2" style={{fontWeight:'400', minWidth:'80px'}}>{ numPrefix(cnt+1) }</div>
                        </div>
                        <div className="pl-10">
                          <h4 className="u-h5 mb-3 mt-1">{itm.heading}</h4>
                          <div dangerouslySetInnerHTML={{ __html:itm.content }}/>
                        </div>
                      </div>
                      )}
                    </Text>
                  </div>
                </section>
              )

            case "WordPressAcf_large_ordered_list_with_image":
              // Large Ordered List with Image
              return (
                <section key={section.id} className="md:my-20 my-16">
                  <div className="o-wrapper">
                    <div className="mx-auto max-w-4xl">
                      {section.list && section.list.length > 0 && section.list.map((itm, cnt)=>
                        <div className="flex flex-wrap items-center md:mb-20 sm:mb-12 mb-10  -ml-6" key={section.id+cnt}>
                          <div className="pl-6 sm:w-5/12 w-full">
                            <AspectRatio className="bg-grey-lighter" ratio="1/1">
                              {itm.image && itm.image.localFile && itm.image.localFile.childImageSharp &&
                                <div className="object-cover">
                                  <Image fluid={itm.image.localFile.childImageSharp.fluid} style={{height:'100%'}} draggable={false} />
                                </div>
                              }
                            </AspectRatio>
                          </div>
                          <div className="pl-6 mt-6 md:mt-0 sm:w-7/12">
                            <div className="sm:max-w-md ml-auto mr-0">
                              <div className="u-h2 mb-3 md:mb-4" style={{fontWeight:'400', minWidth:'80px'}}>{ numPrefix(cnt+1) }</div>
                              <h4 className="u-h5 mb-3 mt-1">{itm.heading}</h4>
                              <div className="mb-3" dangerouslySetInnerHTML={{ __html:itm.content }}/>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              )

            case "WordPressAcf_testimonial":
              // Testimonial
              return (
                <section key={section.id} className="quote my-20">
                  <div className="o-wrapper">
                    <Container className="my-12 py-12 border-b-2 border-t-2 border-grey-light text-center">
                      <QuoteIcon className="mx-auto">
                        <img className="mx-auto" src={require('../images/icons/quote.svg')} alt="Quotation Mark"/>
                      </QuoteIcon>
                      <blockquote className="md:mt-8 mt-6 max-w-lg mx-auto">
                        {section.quote}
                        <cite className="block mt-6 not-italic">
                          {section.cite}
                        </cite>
                      </blockquote>
                    </Container>
                  </div>
                </section>
              )
            case "WordPressAcf_cta_with_image":
              return (
                <section key={section.id} className="md:my-24 sm:my-20 my-16">
                  <div className="o-wrapper">
                    <div className="max-w-4xl mx-auto">
                      <AspectRatio ratio="996/449" className="bg-grey-lighter" style={{minHeight:'250px'}}>
                        <div className="inset-0 p-12 flex flex-col justify-end">
                          <h3 className="u-h4">{section.heading}</h3>
                        </div>
                      </AspectRatio>
                    </div>
                  </div>
                </section>
              )

            case "WordPressAcf_large_ordered_list_with_phone_shot":
              return (
                <section key={section.id} className="md:my-24 sm:my-20 my-16  text-center sm:text-left">
                  <div className="o-wrapper">
                    <div className="mx-auto max-w-3xl">
                      {section.list && section.list.length >0&& section.list.map((itm, cnt) =>
                        <div className="flex flex-wrap items-center lg:mb-24 sm:mb-20 mb-16 -ml-6" key={section.id + cnt}>
                          <div className="pl-6 sm:w-5/12 w-full">
                            <PhoneShot image={itm.image} />
                          </div>
                          <div className="pl-6 mt-6 md:mt-0 sm:w-7/12">
                            <div className="sm:max-w-md ml-auto mr-0">
                              <div className="u-h2 mb-3 md:mb-4" style={{fontWeight:'400', minWidth:'80px'}}>{ numPrefix(cnt+1) }</div>
                              <h4 className="u-h5 mb-3 mt-1">{itm.heading}</h4>
                              <div className="mb-3" dangerouslySetInnerHTML={{ __html:itm.content }} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              )

            default:
              return false
          }
        })}


        {/* HR */}
        <div className="mt-20 mb-10 md:mt-32 md:mb-24">
          <div className="o-wrapper">
            <div className="border-b-2 border-grey-light"></div>
          </div>
        </div>

        {/* Related Guides */}
        {acf.related_articles && acf.related_articles.length > 0 &&
          <RelatedGuides guides={acf.related_articles} />
        }


        {/* Bottom CTA */}
        {/* <div className="bottom-cta lg:my-28 md:my-20 my-16 text-center md:text-left hidden">
          <div className="o-wrapper">
            <div className="bg-grey-lighter md:pt-48 md:pb-12 xl:pb-16 md:px-10 xl:px-16 px-10 py-12">
              <div className="max-w-xl">
                <h3 className="lg:u-h2 u-h3 mb-6">{acf.cta.heading}</h3>
                <p className="text-grey mb-6">{acf.cta.content}</p>
                <button onClick={() => window && window.LC_API && window.LC_API.show_full_view()} className="w-full cursor-pointer flex justify-center md:justify-start items-center">
                  { acf.cta.button.title }
                  <svg width="24" height="13" className="relative ml-3" style={{top:'2px' }} xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" stroke="#2E2E2E" strokeWidth="1.5" fill="none" fillRule="evenodd"><path d="M16.8 0L22.4 5.6 16.8 11.2"/><path d="M22.4 5.6L0.5 5.5"/></g></svg>
                </button>
              </div>
            </div>
          </div>
        </div> */}

        <CTA
          heading={get(acf,'cta.heading')}
          content={get(acf,'cta.content')}
          image={get(acf,'cta.image')}
          button={get(acf,'cta.button')}
        />

      </Layout>
    );
  }

}


export const query = graphql`
  query Guide($id: String!) {
    page: wordpressWpGuides(id: { eq: $id }) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
      }

      id
      title
      slug
      acf {
        guide {
          operating_systems
        }
        hero {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth:996) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          video_type
          video_embed_type
          video_url
        }

        content_guides {
          __typename

          # Intro
          ... on WordPressAcf_introduction {
            id
            heading
            columns {
              heading
              content
            }
          }

          # Embedded video
          ... on WordPressAcf_embedded_video {
            id
            video_url
            video_embed_type
          }

          # Text
          ... on WordPressAcf_text {
            id
            text
          }

          # Callout
          ... on WordPressAcf_callout {
            id
            heading
            text
          }

          # Video Modal
          ... on WordPressAcf_video_modal {
            id
            video_url
            video_embed_type
            poster_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth:793) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }

          # Accordion
          ... on WordPressAcf_accordion {
            id
            accordion {
              heading
              content
            }
          }

          #Two column image and text
          ... on WordPressAcf_two_column_image_text {
            id
            heading
            content
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth:500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }

          # CTA
          ... on WordPressAcf_cta {
            id
            heading
            button {
              url
            }
          }

          # Large Ordered List
          ... on WordPressAcf_large_ordered_list {
            id
            list {
              heading
              content
            }
          }

          # Large Ordered List with Image
          ... on WordPressAcf_large_ordered_list_with_image {
            id
            list {
              heading
              content
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth:500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }

          ... on WordPressAcf_cta_with_image {
            id
            heading

          }

          # Large Ordered List with Mobile image
          ... on WordPressAcf_large_ordered_list_with_phone_shot {
            id
            list {
              heading
              content
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth:500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }

          # Callout
          ... on WordPressAcf_testimonial {
            id
            quote
            cite
          }

        } # end content

        related_articles {
          heading
          teaser
          link
        }

        cta {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth:500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          button {
            title
            url
            target
          }
        }
      }
    }
  }
`;