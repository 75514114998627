import React, { Component } from 'react'
// import PropTypes from 'prop-types'

import Image from "gatsby-image"
import styled from 'styled-components';
import iPhoneShell from '../images/iphone-shell.png';
// import AspectRatio from 'react-aspect-ratio';


const Element = styled.div`
  @media screen and (max-width:640px) {
    height:350px;
  }
`

const WidthContainer = styled.div`
  height:100%;
  width:100%;
  margin:0 auto;
  max-width:222px;

  position:relative;

  &:after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-image:url(${iPhoneShell});
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center;
  }

  > * {
    border-radius: 20px;
    overflow: hidden;
    transform:scale(.88);
    @media screen and (max-width:640px){
      transform:scale(.94);
    }
  }
`

export default class PhoneShot extends Component {
  render() {
    return (
      <Element>
        {this.props.image && this.props.image.localFile && this.props.image.localFile && this.props.image.localFile.childImageSharp && this.props.image.localFile.childImageSharp.fluid &&
          <WidthContainer>
            <Image fluid={this.props.image.localFile.childImageSharp.fluid} className="img--contain h-full" />
          </WidthContainer>
        }
      </Element>
    )
  }
}
