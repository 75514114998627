import React, { Component } from 'react'
import { Link } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components'

const Arrow = styled.div`
  width:23px;
  height:15px;
  margin-left:auto;
    margin-right:0;
  svg {
    height:100%;
    width:100%;
    object-fit:contain;
  }
`;


const GlobalStyle = createGlobalStyle`
  .arrow-move:hover svg  {
    transform: translateX(5px);
  }
`


export default class RelatedGuides extends Component {
  render() {
    return (
      <section className="related-guides md:my-20 my-16">
      <GlobalStyle/>
        <div className="o-wrapper">
          <header className="md:mb-12 mb-8">
            <h3 className="u-h3 text-center sm:text-left">Related Guides</h3>
          </header>

          <div className="grid md:grid-cols-3 sm:grid-cols-2 lg:gap-8 md:gap-6 gap-4">
            {this.props.guides.map((rs, i) =>
              <Link to={rs.link} key={`related-${rs.heading+i}`} className="flex border border-grey-mid px-8 py-10 arrow-move">
                <div>
                  <h4 className="u-h5 mb-3">{rs.heading}</h4>
                  <p className="">{rs.teaser}</p>
                </div>
                <div className="flex flex-col justify-end ml-6">
                  {/* <img src={require('../images/icons/ios.svg')} alt=""/> */}
                  <Arrow>
                    <svg className="transition duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.828 26.828"><path d="M33 13.414H0m21-12l12 12-12 12" fill="none" stroke="#000" strokeWidth="4"/></svg>
                  </Arrow>
                </div>
              </Link>
            )}

          </div>
        </div>
      </section>
    )
  }
}
